import { format, subDays, subMonths, subWeeks, subYears } from 'date-fns'

const getFrom = (period = '30D') => {
  switch (period) {
    case '7D':
      return subWeeks(new Date(), 1)
    case '30D':
      return subDays(new Date(), 30)
    case '3M':
      return subMonths(new Date(), 3)
    case '6M':
      return subMonths(new Date(), 6)
    case '1Y':
      return subYears(new Date(), 1)
    default:
      return subMonths(new Date(), 1)
  }
}

export const ReferrerTypesState = {
  state: () => ({
    yearlyReferrerFetchError: false,
    yearlyCountriesFetchError: false,
    yearlySessionBounceRateFetchError: false,
  }),
  mutations: {
    setFetchError(state, value) {
      state.yearlyReferrerFetchError = value
    },
    setCountriesFetchError(state, value) {
      state.yearlyCountriesFetchError = value
    },
    setSessionBounceRateFetchError(state, value) {
      state.yearlySessionBounceRateFetchError = value
    },
  },
  actions: {
    async loadYearlyReferrerTypesData({ rootState, commit }, period) {
      const dateFrom = format(getFrom(period), 'yyyy-MM-dd')
      const dateTo = format(new Date(), 'yyyy-MM-dd')
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          '/v1/charts/composition/referrer-types?from_date=' +
          dateFrom +
          '&to_date=' +
          dateTo,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setFetchError', true)
            return null
          }
          commit('setFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setFetchError', true)
        })

      return result
    },
    async loadYearlyCountriesData({ rootState, commit }, period) {
      const dateFrom = format(getFrom(period), 'yyyy-MM-dd')
      const dateTo = format(new Date(), 'yyyy-MM-dd')
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          '/v1/charts/composition/countries?from_date=' +
          dateFrom +
          '&to_date=' +
          dateTo,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setFetchError', true)
            return null
          }
          commit('setFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setFetchError', true)
        })

      return result
    },
    async loadYearlySessionBounceRate({ rootState, commit }, period) {
      const dateFrom = format(getFrom(period), 'yyyy-MM-dd')
      const dateTo = format(new Date(), 'yyyy-MM-dd')
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          '/v1/charts/session-length?from_date=' +
          dateFrom +
          '&to_date=' +
          dateTo,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setCountriesFetchError', true)
            return null
          }
          commit('setCountriesFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setCountriesFetchError', true)
        })

      return result
    },
    async loadYearlyReferrers({ rootState, commit }, period) {
      const dateFrom = format(getFrom(period), 'yyyy-MM-dd')
      const dateTo = format(new Date(), 'yyyy-MM-dd')
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          '/v1/charts/composition/referrers?from_date=' +
          dateFrom +
          '&to_date=' +
          dateTo,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setSessionBounceRateFetchError', true)
            return null
          }
          commit('setSessionBounceRateFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setSessionBounceRateFetchError', true)
        })

      return result
    },
  },
}
