import { format, subDays, subMonths, subWeeks, subYears } from 'date-fns'
import { fixLayout } from './dark-theme'

const getFrom = (period) => {
  switch (period) {
    case '7D':
      return subWeeks(new Date(), 1)
    case '30D':
      return subDays(new Date(), 30)
    case '3M':
      return subMonths(new Date(), 3)
    case '6M':
      return subMonths(new Date(), 6)
    case '1Y':
      return subYears(new Date(), 1)
    default:
      return subMonths(new Date(), 1)
  }
}

const initData = (period) => ({
  data: null,
  graphData: null,
  loading: false,
  fetched: false,
  from: format(getFrom(period), 'yyyy-MM-dd'),
  to: format(new Date(), 'yyyy-MM-dd'),
  interval: period === 'year' ? 30 : 1,
  defaultLayout: null,
  fetchError: null,
})

/**
 * Represents the UsersChartData module.
 * @param {string} url - The url for request e.x. '/v1/charts/users'.
 * @param {'week' | 'year'} period - The period for the chart data ('week' or 'year').
 * @param {1 | 7 | 30} interval - The interval for the chart data.
 * @param {string} mode - The mode for the chart data.
 * @returns {Object} - The UsersChartData module object.
 */
export const UsersChartData = (url, period, mode) => ({
  state: () => initData(period),
  mutations: {
    updateData(state, res) {
      state.data = {
        ...res.result.result,
        layout: fixLayout(res.result.result.layout, res.isDarkTheme),
      }
      state.graphData = res.result.data
      state.defaultLayout = res.result.result.layout
    },
    clearData(state) {
      state.data = null
      state.graphData = null
      state.loading = false
      state.fetched = false
      state.from = format(
        period === 'week' ? subWeeks(new Date(), 1) : subYears(new Date(), 1),
        'yyyy-MM-dd',
      )
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.interval = 1
      state.defaultLayout = null
      state.fetchError = null
    },
    setLoading(state, value) {
      state.loading = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
    setPeriod(state, { value }) {
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.from = format(getFrom(value), 'yyyy-MM-dd')
      state.interval = value === 'year' ? 30 : 1
    },
    updateLayout(state, isDarkTheme) {
      if (state.data === null) return
      state.data = {
        ...state.data,
        layout: fixLayout(state.defaultLayout, isDarkTheme),
      }
    },
    setFetchError(state, error) {
      state.fetchError = error
    },
  },
  actions: {
    async loadAppEventsData({ commit, state, rootState }, from) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          `${url}?from_date=${from || state.from}&to_date=${
            state.to
          }&interval=${state.interval}&mode=${mode}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setFetchError', true)
            return null
          }
          commit('setFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setFetchError', true)
        })
      commit('setLoading', false)
      commit('updateData', { result, isDarkTheme: rootState.theme === 'dark' })
      commit('setFetched', true)
    },
    reset({ commit }) {
      commit('clearData')
    },
    setPeriod({ commit, dispatch }, value) {
      commit('setPeriod', { value })
      dispatch('loadAppEventsData', format(getFrom(value), 'yyyy-MM-dd'))
    },
    toggleTheme({ commit }, theme) {
      commit('updateLayout', theme === 'dark')
    },
  },
})
