<template>
  <CNavbarNav class="ms-auto me-4">
    <CButton @click="updateData" size="sm" class="btndark btn btn-light outline"
      style="width: 34px; height: 31px; margin-right: 4px"><svg-icon style="margin-left: -1px; margin-top: -2px"
        :size="18" type="mdi" :path="refresh"></svg-icon></CButton>
    <CDropdown light size="sm" class="shrink link hide-on-small">
      <CDropdownToggle variant="ghost" class="drop1" size="sm">
        {{ refreshLabel }}
      </CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem component="button" class="shrink" @click="setAutoUpdatePeriod(5)">
          5 minutes
        </CDropdownItem>
        <CDropdownItem component="button" class="shrink" @click="setAutoUpdatePeriod(30)">
          30 minutes
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem component="button" class="shrink" @click="setAutoUpdatePeriod(null)">
          Manual refresh
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  </CNavbarNav>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiRefresh } from '@mdi/js'

export default {
  name: 'RefreshButton',
  computed: {
    refreshLabel() {
      switch (this.$store.state.intervalMinutes) {
        case 5:
          return 'Refresh every 5 minutes'
        case 30:
          return 'Refresh every 30 minutes'
        default:
          return 'Manual refresh'
      }
    },
  },
  components: {
    SvgIcon,
  },

  data() {
    return {
      refresh: mdiRefresh,
    }
  },
  methods: {
    updateData() {
      this.$store.dispatch('updateData')
    },
    setAutoUpdatePeriod(value) {
      this.$store.dispatch('setAutoUpdateInterval', value)
    },
  },
}
</script>

<style scoped>
@media (max-width: 650px) {
  .hide-on-small {
    display: none;
  }
}
</style>