import { format, subMonths, subWeeks, subYears } from 'date-fns'

const getFrom = (period) => {
  switch (period) {
    case 'week':
      return subWeeks(new Date(), 1)
    case 'year':
      return subYears(new Date(), 1)
    default:
      return subMonths(new Date(), 1)
  }
}

const initData = (params) => ({
  data: [],
  loading: false,
  fetched: false,
  from: format(subWeeks(new Date(), 1), 'yyyy-MM-dd'),
  to: format(new Date(), 'yyyy-MM-dd'),
  params: params,
  fetchError: null,
})

export const ApplicationGraphicData = (url, params) => ({
  state: initData(params),
  mutations: {
    updateData(state, res) {
      state.data = res
    },
    clearData(state) {
      state.data = []
      state.loading = false
      state.fetched = false
      state.from = format(subWeeks(new Date(), 1), 'yyyy-MM-dd')
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.fetchError = null
    },
    updateFrom(state, { value }) {
      state.from = value
    },
    updateTo(state, { value }) {
      state.to = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
    setPeriod(state, { value }) {
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.from = format(getFrom(value), 'yyyy-MM-dd')
    },
    setParams(state, { value }) {
      state.params = value
    },
    setFetchError(state, error) {
      state.fetchError = error
    },
  },
  actions: {
    async loadAppEventsData({ commit, state, rootState }) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          `${url}?from_date=${state.from}&to_date=${state.to}${
            state.params !== undefined ? '&' + state.params : ''
          }`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
          query: {
            from_date: state.from,
            to_date: state.to,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setFetchError', true)
            return null
          }
          commit('setFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setFetchError', true)
        })
      commit('setLoading', false)
      commit('updateData', result)
      commit('setFetched', true)
    },
    updateParams({ commit, dispatch }, value) {
      commit('setParams', { value })
      dispatch('loadAppEventsData')
    },
    reset({ commit }) {
      commit('clearData')
    },
    setTo({ commit, dispatch }, value) {
      commit('updateTo', { value })
      dispatch('loadAppEventsData')
    },
    setFrom({ commit, dispatch }, value) {
      commit('updateFrom', { value })
      dispatch('loadAppEventsData')
    },
    setPeriod({ commit, dispatch }, value) {
      commit('setPeriod', { value })
      dispatch('loadAppEventsData')
    },
  },
})
