<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar class="btn btn-light outline btndark" shape="rounded">{{
        user?.firstName.charAt(0) + user?.lastName.charAt(0)
      }}</CAvatar>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <RouterLink class="dropdown-item" to="/settings">
        <CIcon icon="cil-settings" /> Settings
      </RouterLink>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import { RouterLink } from 'vue-router'

export default {
  name: 'AppHeaderDropdownAccnt',
  components: {
    RouterLink,
  },
  computed: {
    user() {
      return this.$store.state.User.user
    },
  },
}
</script>

<style>
.dark-theme .dark\:btndark,
.dark-theme .btndark:not([class*="dark:"]) {
  opacity: 0.4;
  background: none !important;
  border: 1px solid white !important;
  color: white !important;
}

/* .avatar {
  background-color: var(--cui-dark) !important;
  color: white;
}

.dark-theme .dark\:avatar, .dark-theme .avatar:not([class*="dark:"]) {
    --cui-bg-opacity: 1;
    background-color: var(--cui-gray-200) !important;
    color: black;
} */
</style>