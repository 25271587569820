<template>
  <CFooter class="mt-5 zone2">
    <div>
      <p class="footer_title">Telemetree</p>
      <p class="footer_text">Built by <a href="https://ton.solutions">TON Solutions</a> in New York,
        USA</p>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style>
.zone2 {
  background-image: linear-gradient(to right bottom, #000000, #070205, #0c050b, #0f0812, #0f0c17, #0f0e1b, #0e101e, #0c1222, #0d1326, #0e132a, #10142d, #121431);
  -webkit-box-shadow: 10px 10px 55px 0px rgb(49, 100, 133) !important;
  -moz-box-shadow: 10px 10px 55px 0px rgb(49, 100, 133) !important;
  box-shadow: 10px 10px 55px 0px rgb(49, 100, 133) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}

.dark-theme .dark\:zone2,
.dark-theme .zone2:not([class*="dark:"]) {
  box-shadow: none !important;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.4);
}

.footer_title {
  color: white;
  align-self: center;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.footer_text {
  color: white;
  align-self: center;
  text-align: center;
  font-weight: 300;
}

.footer_text a {
  color: white !important;
  text-decoration: none;
  border-bottom: 1px solid white;
}
</style>