import merge from 'lodash.merge'

export function fixLayout(layout, isDarkTheme) {
  if (!layout) return null
  const newLayout = isDarkTheme
    ? merge(layout.template.layout, DARK_LAYOUT.template.layout)
    : merge(layout.template.layout, LIGHT_LAYOUT.template.layout)

  return {
    ...layout,
    template: {
      ...layout.template,
      layout: {
        ...newLayout,
      },
      data: {
        ...layout.template.data,
        pie: [
          {
            automargin: false,
            type: 'pie',
          },
        ],
      },
    },
    plot_bgcolor: isDarkTheme
      ? 'rgb(36, 37, 47)'
      : layout.plot_bgcolor || 'white',
    paper_bgcolor: isDarkTheme
      ? 'rgb(36, 37, 47)'
      : layout.paper_bgcolor || undefined,
    height: undefined,
    width: undefined,
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
  }
}

export const DARK_LAYOUT = {
  template: {
    themeRef: 'PLOTLY_DARK',
    layout: {
      colorway: [
        '#636efa',
        '#EF553B',
        '#00cc96',
        '#ab63fa',
        '#19d3f3',
        '#e763fa',
        '#fecb52',
        '#ffa15a',
        '#ff6692',
        '#b6e880',
      ],
      colorscale: {
        sequential: [
          [0, '#0508b8'],
          [0.0893854748603352, '#1910d8'],
          [0.1787709497206704, '#3c19f0'],
          [0.2681564245810056, '#6b1cfb'],
          [0.3575418994413408, '#981cfd'],
          [0.44692737430167595, '#bf1cfd'],
          [0.5363128491620112, '#dd2bfd'],
          [0.6256983240223464, '#f246fe'],
          [0.7150837988826816, '#fc67fd'],
          [0.8044692737430168, '#fe88fc'],
          [0.8938547486033519, '#fea5fd'],
          [0.9832402234636871, '#febefe'],
          [1, '#fec3fe'],
        ],
        sequentialminus: [
          [0, '#0508b8'],
          [0.0893854748603352, '#1910d8'],
          [0.1787709497206704, '#3c19f0'],
          [0.2681564245810056, '#6b1cfb'],
          [0.3575418994413408, '#981cfd'],
          [0.44692737430167595, '#bf1cfd'],
          [0.5363128491620112, '#dd2bfd'],
          [0.6256983240223464, '#f246fe'],
          [0.7150837988826816, '#fc67fd'],
          [0.8044692737430168, '#fe88fc'],
          [0.8938547486033519, '#fea5fd'],
          [0.9832402234636871, '#febefe'],
          [1, '#fec3fe'],
        ],
        diverging: [
          [0, '#8e0152'],
          [0.1, '#c51b7d'],
          [0.2, '#de77ae'],
          [0.3, '#f1b6da'],
          [0.4, '#fde0ef'],
          [0.5, '#f7f7f7'],
          [0.6, '#e6f5d0'],
          [0.7, '#b8e186'],
          [0.8, '#7fbc41'],
          [0.9, '#4d9221'],
          [1, '#276419'],
        ],
      },
      font: {
        color: '#f2f5fa',
      },
      title: {
        x: 0.05,
      },
      paper_bgcolor: 'rgb(36, 37, 47)',
      plot_bgcolor: 'rgb(36, 37, 47)',
      polar: {
        bgcolor: 'rgb(36, 37, 47)',
        angularaxis: {
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
        },
        radialaxis: {
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
        },
      },
      ternary: {
        bgcolor: 'rgb(36, 37, 47)',
        aaxis: {
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
        },
        baxis: {
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
        },
        caxis: {
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
        },
      },
      xaxis: {
        gridcolor: '#283442',
        linecolor: '#506784',
        ticks: '',
        zerolinecolor: '#283442',
      },
      yaxis: {
        gridcolor: '#283442',
        linecolor: '#506784',
        ticks: '',
        zerolinecolor: '#283442',
      },
      scene: {
        xaxis: {
          backgroundcolor: 'rgb(36, 37, 47)',
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
          zerolinecolor: '#C8D4E3',
        },
        yaxis: {
          backgroundcolor: 'rgb(36, 37, 47)',
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
          zerolinecolor: '#C8D4E3',
        },
        zaxis: {
          backgroundcolor: 'rgb(36, 37, 47)',
          gridcolor: '#506784',
          linecolor: '#506784',
          ticks: '',
          zerolinecolor: '#C8D4E3',
        },
      },
      shapedefaults: {
        fillcolor: '#f2f5fa',
        line: {
          width: 0,
        },
        opacity: 0.4,
      },
      annotationdefaults: {
        arrowcolor: '#f2f5fa',
        arrowhead: 0,
        arrowwidth: 1,
      },
      geo: {
        bgcolor: 'rgb(36, 37, 47)',
        subunitcolor: '#506784',
        showland: true,
        showlakes: true,
        lakecolor: 'rgb(36, 37, 47)',
      },
      updatemenudefaults: {
        bgcolor: '#506784',
        borderwidth: 0,
      },
      sliderdefaults: {
        bgcolor: '#C8D4E3',
        borderwidth: 1,
        bordercolor: 'rgb(36, 37, 47)',
        tickwidth: 0,
      },
    },
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
  },
}

export const LIGHT_LAYOUT = {
  template: {
    themeRef: 'PLOTLY_LIGHT',
    layout: {
      font: {
        color: 'rgb(36, 37, 47)',
      },
      paper_bgcolor: 'white',
      plot_bgcolor: 'white',
      polar: {
        bgcolor: 'white',
      },
      ternary: {
        bgcolor: 'white',
      },
      scene: {
        xaxis: {
          backgroundcolor: 'white',
        },
        yaxis: {
          backgroundcolor: 'white',
        },
        zaxis: {
          backgroundcolor: 'white',
        },
      },
      geo: {
        bgcolor: 'white',
        lakecolor: 'white',
      },
      sliderdefaults: {
        bgcolor: '#C8D4E3',
        borderwidth: 1,
        bordercolor: 'white',
        tickwidth: 0,
      },
    },
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
  },
}
