<template>
  <AppSidebar />
  <div class="wrapper d-flex flex-column min-vh-100 dark:bg-transparent ">
    <AppHeader />
    <div class="body flex-grow-1 px-3">
      <div class="loader-wrapper" v-if="!fetchUser">
        <Loader dark />
      </div>
      <CContainer lg v-if="fetchUser">
        <router-view />
      </CContainer>
    </div>
    <AppFooter />
    <Toaster />
  </div>
</template>

<script>
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import Loader from '@/components/Loader.vue'
import Toaster from '@/components/Toaster.vue'
import { CContainer } from '@coreui/vue-pro'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    Toaster,
    Loader,
  },
  computed: {
    fetchUser() {
      return this.$store.state.User.fetched
    },
  },
}
</script>

<style scoped>
.loader-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
</style>
