export default [
  {
    component: 'CNavItem',
    name: 'Applications',
    to: '/',
    icon: 'cil-layers',
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: 'CNavItem',
    name: 'Event Segmentation',
    to: '/custom-graphics',
    icon: 'cil-chart-pie',
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
]
