import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: '',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        children: [
          {
            path: '',
            name: 'Applications',
            component: () => import('@/views/Application.vue'),
          },
          {
            path: 'settings',
            name: 'Application Settings',
            component: () => import('@/views/ApplicationSettings.vue'),
          },
          {
            path: 'events',
            name: 'Application Events',
            component: () => import('@/views/ApplicationEvents.vue'),
          },
          {
            path: 'custom-graphics',
            name: 'Event segmentation',
            component: () => import('@/views/ApplicationCustomGraphics.vue'),
          },
        ],
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/Settings.vue'),
      },
      {
        path: 'callback',
        name: 'Callback Page',
        redirect: (to) => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return { path: '/', query: { ...to.query } }
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
