<template>
  <CToaster>
    <CToast
      v-for="message in messages"
      :key="message.id"
      :autohide="true"
      :close="() => hideToast(message.id)"
      visible
    >
      <CToastHeader closeButton>
        <span class="me-auto fw-bold">{{
          getTimeAfter(message.timestamp)
        }}</span>
      </CToastHeader>
      <CToastBody>{{ message.text }}</CToastBody>
    </CToast>
  </CToaster>
</template>

<script>
import { formatDistance } from 'date-fns'

export default {
  computed: {
    messages() {
      return this.$store.state.ToastModule.messages
    },
  },
  methods: {
    hideToast(id) {
      this.$store.dispatch({
        type: 'hideToast',
        id,
      })
    },
    getTimeAfter(time) {
      return formatDistance(new Date(), new Date(time), { addSuffix: true })
    },
  },
}
</script>
