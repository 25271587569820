import { format, subDays, subMonths, subWeeks, subYears } from 'date-fns'
import { fixLayout } from './dark-theme'

const getFrom = (period) => {
  switch (period) {
    case '7D':
      return subWeeks(new Date(), 1)
    case '30D':
      return subDays(new Date(), 30)
    case '3M':
      return subMonths(new Date(), 3)
    case '6M':
      return subMonths(new Date(), 6)
    case '1Y':
      return subYears(new Date(), 1)
    default:
      return subMonths(new Date(), 1)
  }
}

const initData = (type) => ({
  data: null,
  graphData: null,
  loading: false,
  fetched: false,
  from: format(getFrom(type), 'yyyy-MM-dd'),
  to: format(new Date(), 'yyyy-MM-dd'),
  defaultLayout: null,
  fetchError: null,
})

export const ApplicationRemoteGraphicData = (url, params, type = '30D') => ({
  state: initData(type),
  mutations: {
    updateData(state, res) {
      state.data = {
        ...res.result.result,
        layout: fixLayout(res.result.result.layout, res.isDarkTheme),
      }
      state.graphData = res.result.data
      state.defaultLayout = res.result.result.layout
    },
    clearData(state) {
      state.data = null
      state.graphData = null
      state.loading = false
      state.fetched = false
      format(getFrom(type), 'yyyy-MM-dd')
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.defaultLayout = null
      state.fetchError = null
    },
    updateFrom(state, { value }) {
      state.from = value
    },
    updateTo(state, { value }) {
      state.to = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
    setPeriod(state, { value }) {
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.from = format(getFrom(value), 'yyyy-MM-dd')
    },
    updateLayout(state, isDarkTheme) {
      if (state.data === null) return
      state.data = {
        ...state.data,
        layout: fixLayout(state.defaultLayout, isDarkTheme),
      }
    },
    setFetchError(state, error) {
      state.fetchError = error
    },
  },
  actions: {
    async loadAppEventsData({ commit, state, rootState }) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          `${url}?from_date=${state.from}&to_date=${state.to}${
            params !== undefined ? '&' + params : ''
          }`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      )
        .then((res) => {
          if (!res.ok) {
            commit('setFetchError', true)
            return null
          }
          commit('setFetchError', false)
          return res.json()
        })
        .catch(() => {
          commit('setFetchError', true)
        })
      commit('setLoading', false)
      commit('setFetched', true)
      commit('updateData', { result, isDarkTheme: rootState.theme === 'dark' })
    },
    reset({ commit }) {
      commit('clearData')
    },
    setTo({ commit, dispatch }, value) {
      commit('updateTo', { value })
      dispatch('loadAppEventsData')
    },
    setFrom({ commit, dispatch }, value) {
      commit('updateFrom', { value })
      dispatch('loadAppEventsData')
    },
    setPeriod({ commit, dispatch }, value) {
      commit('setPeriod', { value })
      dispatch('loadAppEventsData')
    },
    toggleTheme({ commit }, theme) {
      commit('updateLayout', theme === 'dark')
    },
  },
})
