import { format, subDays, subMonths, subWeeks, subYears } from 'date-fns'

const getFrom = (period) => {
  switch (period) {
    case '7D':
      return subWeeks(new Date(), 1)
    case '30D':
      return subDays(new Date(), 30)
    case '3M':
      return subMonths(new Date(), 3)
    case '6M':
      return subMonths(new Date(), 6)
    case '1Y':
      return subYears(new Date(), 1)
    default:
      return subMonths(new Date(), 1)
  }
}

const initData = (period) => ({
  data: null,
  loading: false,
  fetched: false,
  from: format(getFrom(period), 'yyyy-MM-dd'),
  to: format(new Date(), 'yyyy-MM-dd'),
  interval: period === 'year' ? 30 : 1,
  defaultLayout: null,
})

/**
 * Represents the UsersChartData module.
 * @param {string} url - The url for request e.x. '/v1/charts/users'.
 * @param {'7D' | '30D' | '3M' | '6M' | '1Y'} period - The period for the chart data ('week' or 'year').
 * @param {string} mode - The mode for the chart data.
 * @returns {Object} - The UsersChartData module object.
 */
export const UsersGraphData = (url, period, mode) => ({
  state: () => initData(period),
  mutations: {
    updateData(state, res) {
      state.data = res
    },
    clearData(state) {
      state.data = null
      state.loading = false
      state.fetched = false
      state.from = format(getFrom(period), 'yyyy-MM-dd')
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.interval = 1
    },
    setLoading(state, value) {
      state.loading = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
    setPeriod(state, { value }) {
      state.to = format(new Date(), 'yyyy-MM-dd')
      state.from = format(getFrom(value), 'yyyy-MM-dd')
      state.interval = value === '1Y' ? 30 : 1
    },
  },
  actions: {
    async loadAppEventsData({ commit, state, rootState }, from) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST +
          `${url}?from_date=${from || state.from}&to_date=${
            state.to
          }&interval=${state.interval}&mode=${mode}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      ).then((res) => {
        return res.json()
      })
      commit('setLoading', false)
      commit('updateData', result)
      commit('setFetched', true)
    },
    reset({ commit }) {
      commit('clearData')
    },
    setPeriod({ commit, dispatch }, value) {
      commit('setPeriod', { value })
      dispatch('loadAppEventsData', format(getFrom(value), 'yyyy-MM-dd'))
    },
  },
})
