<template>
  <img
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
    src="../assets/logo-dark.svg"
    v-if="showDark"
  />
  <img
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
    src="../assets/logo.svg"
    v-else
  />
</template>

<script>
export default {
  name: 'Loader',
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 64,
    },
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    showDark() {
      if (this.dark) {
        return this.theme !== 'dark'
      }
      return this.theme === 'dark'
    },
  },
}
</script>

<style scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

img {
  width: 64px;
  height: 64px;
  animation: spin infinite 5s linear;
}
</style>
