export const UserModule = {
  state: () => ({
    user: null,
    project: null,
    applications: [],
    selectedApplication: null,
    loading: false,
    fetched: false,
  }),
  mutations: {
    updateUser(state, res) {
      state.user = res.user
      state.project = res.project
      state.applications = [res.application]
      if (
        ![res.application]
          .map((el) => el.id)
          .includes(state.selectedApplication)
      ) {
        state.selectedApplication = res.application.id
      }
    },
    clearData(state) {
      state.user = null
      state.project = null
      state.applications = []
    },
    setProjectName(state, { value }) {
      state.project.name = value
    },
    setLoading(state, value) {
      state.loading = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
    switchApplication(state, applicationId) {
      state.selectedApplication = applicationId
    },
  },
  getters: {
    getApplicationById: (state) => () => {
      return (
        state.applications.find(
          (app) => app.id === state.selectedApplication,
        ) ||
        state.applications[0] ||
        null
      )
    },
  },
  actions: {
    async loadUserData({ commit }, token) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST + '/v1/users/me',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).then((res) => {
        commit('setLoading', false)
        return res.json()
      })
      commit('updateUser', result)
      commit('setFetched', true)
    },
    logout({ commit }) {
      commit('clearData')
    },
    async updateProjectName({ state, rootState, dispatch }) {
      await fetch(
        `${process.env.VUE_APP_API_HOST}/v1/projects/${state.project.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
          body: JSON.stringify({
            name: state.project.name,
          }),
        },
      ).then((res) => {
        if (res.ok) {
          dispatch('showToast', 'Project name updated')
        } else {
          dispatch('showToast', 'Failed to update project name')
        }
      })
    },
    async updateApplication({ rootState, dispatch }, { form }) {
      await fetch(`${process.env.VUE_APP_API_HOST}/v1/projects/applications/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${rootState.AuthModule.token}`,
        },
        body: JSON.stringify(form),
      }).then((res) => {
        if (res.ok) {
          dispatch('showToast', 'Application updated')
        } else {
          dispatch('showToast', 'Failed to update application')
        }
      })
    },
    switchApplication({ commit }, applicationId) {
      commit('switchApplication', applicationId)
    },
  },
}
