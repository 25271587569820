import { createStore } from 'vuex'
import {
  ApplicationCustomGraphic,
  ApplicationEventsModule,
  ApplicationGraphicData,
  ApplicationRealtimeUsersModule,
  ApplicationRemoteGraphicData,
  AuthModule,
  SettingPeopleModule,
  ToastModule,
  UserModule,
  UsersChartData,
  UsersGraphData,
} from './modules'
import { ReferrerTypesState } from './modules/referrer-types'

export default createStore({
  state: {
    asideVisible: false,
    sidebarVisible: false,
    sidebarUnfoldable: false,
    theme: 'default',
    intervalId: null,
    intervalMinutes: null,
    period: '30D',
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setIntervalId(state, id) {
      state.intervalId = id
    },
    setAutoUpdateIntervalMinutes(state, minutes) {
      state.intervalMinutes = minutes
    },
    setPeriod(state, value) {
      state.period = value
    },
  },
  actions: {
    updateData() {},
    setAutoUpdateInterval({ commit, dispatch }, minutes) {
      let intervalId = null
      if (this.state.intervalId !== null) clearInterval(this.state.intervalId)
      if (minutes !== null) {
        intervalId = setInterval(
          () => {
            dispatch('updateData')
          },
          1000 * 60 * minutes,
        )
      }
      commit('setAutoUpdateIntervalMinutes', minutes)
      commit('setIntervalId', intervalId)
    },
    setGeneralPeriod({ commit }, value) {
      commit('setPeriod', value)
    },
  },
  modules: {
    AuthModule,
    User: UserModule,
    ReferrerTypes: ReferrerTypesState,
    ToastModule,
    Application: {
      namespaced: true,
      modules: {
        Countries: {
          namespaced: true,
          ...ApplicationRemoteGraphicData(
            '/plotly/graphs/composition/countries',
          ),
        },
        SessionTime: {
          namespaced: true,
          ...ApplicationRemoteGraphicData('/plotly/graphs/session-length'),
        },
        Platforms: {
          namespaced: true,
          ...ApplicationRemoteGraphicData(
            '/plotly/graphs/composition/platforms',
          ),
        },
        Devices: {
          namespaced: true,
          ...ApplicationRemoteGraphicData('/plotly/graphs/composition/devices'),
        },
        UsersPerformsComparing: {
          namespaced: true,
          ...ApplicationRemoteGraphicData('/plotly/graphs/rtu-comparsion', ''),
        },
        Referrers: {
          namespaced: true,
          ...ApplicationGraphicData('/v1/charts/composition/referrers'),
        },
        ReferrersTypes: {
          namespaced: true,
          ...ApplicationRemoteGraphicData(
            '/plotly/graphs/composition/referrer-types',
          ),
        },
        ReferrersTypesForYear: {
          namespaced: true,
          ...ApplicationGraphicData('/v1/charts/composition/referrer-types'),
        },
        UsersForYear: {
          namespaced: true,
          ...UsersGraphData('/v1/charts/users', '30D', 'new'),
        },
        UsersForWeek: {
          namespaced: true,
          ...UsersGraphData('/v1/charts/users', '30D', 'active'),
        },
        UsersSession: {
          namespaced: true,
          ...ApplicationGraphicData('/v1/charts/session-average'),
        },
        ActiveUsers: {
          namespaced: true,
          ...UsersChartData('/plotly/graphs/users', '30D', 'active'),
        },
        NewUsers: {
          namespaced: true,
          ...UsersChartData('/plotly/graphs/users', '30D', 'new'),
        },
        UsersSegmentation: {
          namespaced: true,
          ...ApplicationGraphicData(
            '/v1/charts/event-segmentation',
            'event_type=Pageview /',
          ),
        },
        CustomSegmentation: {
          namespaced: true,
          ...ApplicationGraphicData('/plotly/graphs/event-segmentation'),
        },
        RealTimeUsers: {
          namespaced: true,
          ...ApplicationRealtimeUsersModule,
        },
        NWeekRetention: {
          namespaced: true,
          ...ApplicationRemoteGraphicData('/plotly/graphs/retention', ''),
        },
        Events: {
          namespaced: true,
          ...ApplicationEventsModule,
        },
        SettingPeople: {
          namespaced: true,
          ...SettingPeopleModule,
        },
        SegmentationGraph: {
          namespaced: true,
          ...ApplicationCustomGraphic,
        },
      },
    },
  },
})
