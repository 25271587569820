export const ToastModule = {
  state: () => ({
    messages: [],
  }),
  mutations: {
    dropMessage(state, id) {
      state.messages = state.messages.filter((m) => m.id !== id)
    },
    addMessage(state, message) {
      state.messages.push({
        id: Math.random().toString(36).substr(2, 9),
        text: message,
        timestamp: Date.now(),
      })
    },
  },
  actions: {
    showToast({ commit }, text) {
      commit('addMessage', text)
    },
    closeToast({ commit }, id) {
      commit('dropMessage', id)
    },
  },
}
