<template>
  <div class="gradient"></div>
  <CNavbar expand="lg" class="header-bg">
    <CContainer>
      <CHeaderBrand href="/" class="animate__animated animate__swing animate__infinite mt-3 mb-0 animate__swing" to="/">
        <CIcon :icon="logo" height="42" alt="Telemetree" v-if="theme !== 'dark'" />
        <CIcon :icon="logoNegative" height="42" alt="Telemetree" v-else />
      </CHeaderBrand>
      <CNavbarToggler aria-expanded="{visible}" @click="visible = !visible" class="ps-1" />
      <CCollapse class="navbar-collapse" :visible="visible">
        <CNavbarNav class="mb-2 mb-lg-0" style="display: flex; align-items: center; justify-content: center">
          <CNavItem>
            <RouterLink :to="{ name: 'Applications' }" class="nav-link main-menu" exact-active-class="active">
              Applications
            </RouterLink>
          </CNavItem>
          <!-- <CNavItem>
            <RouterLink :to="{ name: 'Event segmentation' }" class="nav-link main-menu" exact-active-class="active">
              Segmentation
            </RouterLink>
          </CNavItem> -->
          <CNavItem>
            <CNavLink class="main-menu" href="https://docs.ton.solutions/docs/getting-started">Documentation
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink class="main-menu" href="https://medium.com/@ton_solutions/">Blog</CNavLink>
          </CNavItem>
          <!-- <Tour /> -->
        </CNavbarNav>
      </CCollapse>
      <RefreshButton />
      <CHeaderNav>
        <CButtonGroup size="sm" aria-label="Theme switch">
          <CFormCheck id="btn-light-theme" type="radio" :button="{ class: 'btn btn-light outline btndark' }"
            name="theme-switch" autocomplete="off" :checked="$store.state.theme === 'default'" @change="(event) =>
              $store.commit({
                type: 'toggleTheme',
                value: 'default',
              })
              ">
            <template #label>
              <CIcon icon="cil-sun" />
            </template>
          </CFormCheck>
          <CFormCheck id="btn-dark-theme" type="radio" :button="{ class: 'btn btn-light outline btndark2 brdr' }"
            name="theme-switch" autocomplete="off" :checked="$store.state.theme === 'dark'" @change="(event) =>
              $store.commit({
                type: 'toggleTheme',
                value: 'dark',
              })
              ">
            <template #label>
              <CIcon icon="cil-moon" />
            </template>
          </CFormCheck>
        </CButtonGroup>
      </CHeaderNav>
      <CHeaderNav class="ms-3 me-4 hide-on-small">
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CNavbar>
</template>

<script>
import { logo } from '@/assets/brand/logo'
import { logoNegative } from '@/assets/brand/logo-negative'
import { mdiRefresh } from '@mdi/js'
import { RouterLink } from 'vue-router'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import RefreshButton from './RefreshButton.vue'

export default {
  name: 'AppHeader',
  computed: {
    theme() {
      return this.$store.state.theme
    },
    application() {
      return this.$store.getters.getApplicationById()
    },
    projectName: {
      get() {
        return this.$store.state.User.project?.name
      },
      set(value) {
        this.$store.commit({
          type: 'setProjectName',
          value,
        })
      },
    },
    projectId() {
      return this.$store.state.User.project?.id
    },
    projectUrl() {
      return this.$store.state.User.project?.projectName
    },
    applicationList() {
      return this.$store.state.User.applications.map((application) => ({
        label: application.name,
        value: application.id,
      }))
    },
    applicationId: {
      get() {
        return this.$store.state.User.selectedApplication
      },
      set(value) {
        this.$store.dispatch('switchApplication', value)
      },
    },
  },
  components: {
    AppHeaderDropdownAccnt,
    RouterLink,
    RefreshButton,
  },
  setup() {
    return {
      logo,
      logoNegative,
    }
  },
  data() {
    return {
      refresh: mdiRefresh,
      visible: false,
    }
  },
  methods: {
    updateData() {
      this.$store.dispatch('updateData')
    },
  },
}
</script>

<style scoped>
.fix-logo-class {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style>
.navbar-toggler {
  border: 0px !important;
}

.dark-theme .dark\:header-bg,
.dark-theme .header-bg:not([class*='dark:']) {
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom: 1px solid rgba(145, 135, 135, 0.05);
}

.header-bg {
  background-color: white;
  border-bottom: 1px solid rgba(145, 135, 135, 0.15);
}

.gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(172, 216, 244, 0.3) 62%,
      rgba(101, 176, 223, 0.2) 100%);
  height: 100px;
  width: 100%;
  position: absolute;
  z-index: -10;
}

.dark-theme .dark\:main-menu,
.dark-theme .main-menu:not([class*='dark:']) {
  color: rgba(255, 255, 255, 0.7);
}

.brdr {
  border-right: 0px !important;
  border-left: 0px !important;
}

.dark-theme .dark\:brdr,
.dark-theme .brdr:not([class*='dark:']) {
  border-left: 0px !important;
  border-top: 1px solid white !important;
  border-right: 1px solid white !important;
  border-bottom: 1px solid white !important;
}

.dark-theme .dark\:bg-primary,
.dark-theme .gradient:not([class*='dark:']) {
  --cui-bg-opacity: 1;
  visibility: hidden;
}

.dark-theme .dark\:drop1,
.dark-theme .drop1:not([class*='dark:']) {
  color: white;
  opacity: 0.5;
}

/* .dark-theme .dark\:ref, .dark-theme .ref:not([class*="dark:"]) {
    color: black;
    background-color: rgb(0,0,21) !important;
} */
.dark-theme .dark\:btndark,
.dark-theme .btndark:not([class*='dark:']) {
  opacity: 0.5;
  background: none !important;
  color: white !important;
}

.dark-theme .dark\:btndark2,
.dark-theme .btndark2:not([class*='dark:']) {
  opacity: 0.4;
  background: #d5d5d559 !important;
  color: white !important;
}

.shrink {
  font-size: 14px;
}

.tour-header {
  font-weight: 400;
}

a.active.nav-link {
  font-weight: 500 !important;
}

a.active.nav-link.main-menu {
  font-weight: 600 !important;
}

@media (max-width: 650px) {
  .hide-on-small {
    display: none;
  }
}
</style>
