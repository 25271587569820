export const SettingPeopleModule = {
  state: () => ({
    peoples: [],
    loading: false,
    fetched: false,
    modalVisible: false,
    addUserPending: false,
  }),
  mutations: {
    clearData(state) {
      state.peoples = []
      state.loading = false
      state.fetched = false
      state.modalVisible = false
      state.addUserPending = false
    },
    setData(state, value) {
      state.peoples = value.members
    },
    setLoading(state, value) {
      state.loading = value
    },
    setUserPending(state, value) {
      state.addUserPending = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
    toggleModal(state, value) {
      state.modalVisible = value || !state.modalVisible
    },
  },
  actions: {
    async getPeopleList({ commit, rootState }) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST + `/v1/projects/members/`,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
          method: 'GET',
        },
      ).then((res) => {
        commit('setLoading', false)
        return res.json()
      })
      commit('setData', result)
      commit('setFetched', true)
    },
    async inviteMember({ commit, rootState, dispatch }, newUser) {
      commit('setUserPending', true)
      await fetch(process.env.VUE_APP_API_HOST + `/v1/projects/members/`, {
        headers: {
          Authorization: `Bearer ${rootState.AuthModule.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
        method: 'POST',
      }).then((res) => {
        if (res.ok) {
          dispatch('showToast', 'User invited successfully', { root: true })
          commit('setUserPending', false)
          dispatch('getPeopleList')
          dispatch('hideModal')
        } else {
          commit('setUserPending', false)
          dispatch('showToast', 'Failed to invite user', { root: true })
        }
      })
    },
    async deleteMember({ commit, rootState, dispatch }, memberId) {
      commit('setLoading', true)
      await fetch(
        process.env.VUE_APP_API_HOST + `/v1/projects/members/${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
          method: 'DELETE',
        },
      ).then(() => {
        commit('setLoading', false)
        dispatch('getPeopleList')
        commit('setFetched', true)
      })
    },
    logout({ commit }) {
      commit('clearData')
    },
    showModal({ commit }) {
      commit('toggleModal', true)
    },
    hideModal({ commit }) {
      commit('toggleModal', false)
    },
  },
}
