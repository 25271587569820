export const ApplicationEventsModule = {
  state: () => ({
    events: [],
    loading: false,
    fetched: false,
    page: 1,
  }),
  mutations: {
    updateEvents(state, res) {
      state.events = res.events
    },
    clearData(state) {
      state.events = []
      state.loading = false
      state.fetched = false
      state.page = 0
      state.pageSize = 10
    },
    addPage(state, value) {
      state.events = value
      state.page++
    },
    setLoading(state, value) {
      state.loading = value
    },
    setFetched(state, value) {
      state.fetched = value
    },
  },
  actions: {
    async loadAppEventsData({ commit, rootState }) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST + `/v1/events/?limit=10`,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      ).then((res) => {
        commit('setLoading', false)
        return res.json()
      })
      commit('addPage', result)
      commit('setFetched', true)
    },
    logout({ commit }) {
      commit('clearData')
    },
    async updateEventsName({ rootState, commit }) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST + `/v1/events/?limit=10`,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
        },
      ).then((res) => {
        commit('setLoading', false)
        return res.json()
      })
      commit('updateEvents', result)
    },
  },
}
