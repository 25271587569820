export const AuthModule = {
  state: () => ({
    token: null,
  }),
  mutations: {
    authenticate(state, token) {
      state.token = token
    },
    logout(state) {
      state.token = null
    },
  },
  actions: {
    login({ commit }, token) {
      commit('authenticate', token)
      window.localStorage.setItem('token', token)
    },
    logout({ commit }) {
      commit('logout')
    },
    checkToken({ dispatch }) {
      let token = window.localStorage.getItem('token')
      const searchParams = new URLSearchParams(window.location.search)
      const query = Object.fromEntries(searchParams.entries())
      if (query.token) {
        token = query.token
      }
      if (token) {
        dispatch('login', token)
        dispatch('loadUserData', token, { root: true })
      } else {
        window.location.replace(
          process.env.VUE_APP_REDIRECT_URL_FOR_NON_LOGIN_USER,
        )
      }
    },
  },
}
