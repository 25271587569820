export const ApplicationCustomGraphic = {
  state: () => ({
    eventList: [],
    events: [],
    measured: 'uniques',
    segmentBy: [],
    groupBy: [],
    loading: false,
    fetched: false,
    data: null,
    graphData: null,
  }),
  mutations: {
    setEvents(state, events) {
      state.events = events
    },
    setMeasured(state, measured) {
      state.measured = measured
    },
    setLoading(state, loading) {
      state.loading = loading
    },
    setFetched(state, fetched) {
      state.fetched = fetched
    },
    setSegmentBy(state, segmentBy) {
      state.segmentBy = segmentBy
    },
    setGroupBy(state, groupBy) {
      state.groupBy = groupBy
    },
    setEventList(state, eventList) {
      state.eventList = eventList
    }
  },
  getters: {
    getApplicationById: (state) => () => {
      return (
        state.applications.find(
          (app) => app.id === state.selectedApplication,
        ) ||
        state.applications[0] ||
        null
      )
    },
  },
  actions: {
    async loadUserData({ commit }, token) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST + '/v1/users/me',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ).then((res) => {
        commit('setLoading', false)
        return res.json()
      })
      commit('updateUser', result)
      commit('setFetched', true)
    },
    async getEvents({ dispatch, commit, rootState }) {
      commit('setLoading', true)
      const result = await fetch(
        process.env.VUE_APP_API_HOST + `/v1/events/list`,
        {
          headers: {
            Authorization: `Bearer ${rootState.AuthModule.token}`,
          },
          method: 'GET',
        },
      )

      const events = await result.json()
      console.log(events)
      dispatch('updateEventList', events)
    },
    updateEventList({ commit }, eventList) {
      commit('setEventList', eventList)
    },
    updateEvents({ commit }, events) {
      commit('setEvents', events)
    },
    updateMeasured({ commit }, measured) {
      commit('setMeasured', measured)
    },
    updateSegmentBy({ commit }, segmentBy) {
      commit('setSegmentBy', segmentBy)
    },
    updateGroupBy({ commit }, groupBy) {
      commit('setGroupBy', groupBy)
    },
  },
}
